<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="px-5 py-10"
          >
            <div class="d-flex justify-center">
              <v-img
                src="@/assets/travikr-logo-color-login.png"
                contain
                max-width="300"
              />
            </div>

            <v-card>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <p class="textHeader px-7 pt-5">
                    Login CMS
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <p class="textSubHeader px-7 mt-n8">
                    Selamat datang kembali di Travikr
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="px-10">
                  <v-form @keyup.enter.native="login">
                    <v-text-field
                      v-model="email"
                      name="email"
                      color="purple"
                      type="text"
                      label="Masukan email anda"
                      outlined
                      autocomplete="off"
                    />

                    <v-text-field
                      id="password"
                      v-model="password"
                      outlined
                      label="Masukan kata sandi anda"
                      name="password"
                      color="purple"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (showPassword = !showPassword)"
                    />
                  </v-form>
                </v-col>
              </v-row>

              <v-row v-if="showAlert">
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  class="px-10 my-n5"
                >
                  <v-alert
                    v-model="showAlert"
                    :type="alertType"
                    dense
                    dismissible
                    class="px-10"
                  >
                    {{ alertText }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  class="px-10 pb-10"
                >
                  <v-btn
                    color="#7b1877"
                    block
                    x-large
                    @click="login"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>

              <!-- <v-divider /> -->
            </v-card>

            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <p class="text-center pt-2">
                  ©Travikr.com 2020
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

  export default {
    name: 'Login',
    data () {
      return {
        email: '',
        password: '',
        showAlert: false,
        alertType: 'success',
        alertText: 'Success!',
        showPassword: false,
      }
    },
    methods: {
      login () {
        const vm = this
        // if email or password empty return immediately
        if (this.email.trim() === '' || this.password.trim() === '') {
          // this.email = ''
          // this.password = ''
          return
        }
        // define request body
        const requestBody = {
          email: this.email,
          password: this.password,
        }
        // call api login
        axios.post('/t/login', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200 && res.data.data.block_login) {
            this.fnShowAlert('error', res.data.data.block_login_msg)
            return
          }
          if (res.data.status === 200 && !res.data.data.block_login) {
            // console.log(res.data)
            localStorage.setItem('token', res.data.data.login_token)
            localStorage.setItem('userMenus', (res.data.data.cms_access).toString())
            localStorage.setItem('userinfo', JSON.stringify(res.data.data))
            vm.$router.push({ name: 'Dashboard' })
          }
        }).catch((e) => {
          this.fnShowAlert('error', e.response.data.error)
          // vm.$router.go()
        })
      },

      fnShowAlert (type, text) {
        this.alertType = type
        this.alertText = text
        this.showAlert = true
        // setTimeout(() => {
        //   this.showAlert = false
        // }, 3000)
      },
    },
  }
</script>

<style scoped>
  .textHeader {
    font-size: 26px !important;
    font-weight: 700 !important;
  }

  .textSubHeader {
    font-size: 18px !important;
  }
</style>
